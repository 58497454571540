<template>
  <div class="userForm" v-if="!isLoading">
    <div class="profile">
      <div class="row" style="margin: 20px 0">
        <div class="col-6">
          <dl>
                <dt>ID:</dt>
                <dd>{{ customer.id }}</dd>

                <dt>Fullname:</dt>
                <dd>{{ customer.fullname }}</dd>

                <dt>Email:</dt>
                <dd>{{ customer.email }}</dd>

                <dt>Account Enabled:</dt>
                <dd>{{ customer.enabled }}</dd>

                <dt>Password Reset Mode:</dt>
                <dd>{{ customer.reset }}</dd>

                <dt>Country:</dt>
                <dd>{{ customer.country }}</dd>

                <dt>Last Login Platform:</dt>
                <dd>{{ customer.last_login_platform }}</dd>

                <dt>Last Login Time:</dt>
                <dd>{{ customer.last_login_time | dateformat }}</dd>

                <dt>Created On:</dt>
                <dd>{{ customer.created_on | dateformat}}</dd>

                <dt>Payment System:</dt>
                <dd>{{ customer.payment_system }}</dd>

                <dt>Sign Up Platform:</dt>
                <dd>{{ customer.sign_up_platform }}</dd>

                <dt>Subscribed On:</dt>
                <dd>{{ customer.subscribed_on | dateformat }}</dd>

                <dt>Renewal Date:</dt>
                <dd>{{ customer.renewal_date | dateformat }}</dd>

                <dt>Cancelled On:</dt>
                <dd>{{ customer.cancelled_on | dateformat }}</dd>

                <dt>Plan:</dt>
                <dd>{{ customer.plan }}</dd>

                <dt>Found out From:</dt>
                <dd>{{ customer.found_out_from }}</dd>
          </dl>
        </div>
        <div class="col-6">
          <dl>
            

            <dt v-if="hasGoogleData()">Purchase Token (Google):</dt>
            <dd v-if="hasGoogleData()">{{ customer.google_purchase_token }}</dd>

            <dt v-if="hasGoogleData()">Auto Renewal Status (Google):</dt>
            <dd v-if="hasGoogleData()">{{ customer.google_auto_renewing }}</dd>

            <dt v-if="hasGoogleData()">Order ID (Google):</dt>
            <dd v-if="hasGoogleData()">{{ customer.google_order_id }}</dd>

            <dt v-if="hasGoogleData()">Product ID (Google):</dt>
            <dd v-if="hasGoogleData()">{{ customer.google_product_id }}</dd>
            
            <dt v-if="hasGoogleData()">Purchase Time (Google):</dt>
            <dd v-if="hasGoogleData()">{{ customer.google_purchase_time }}</dd>

            <dt v-if="hasGoogleData()">Purchase State (Google):</dt>
            <dd v-if="hasGoogleData()">{{ customer.google_purchase_state }}</dd>

            <dt v-if="hasGoogleData()">Acknowledged (Google):</dt>
            <dd v-if="hasGoogleData()">{{ customer.google_acknowledged }}</dd>

            <dt v-if="hasGoogleData()">Base Plan (Google):</dt>
            <dd v-if="hasGoogleData()">{{ customer.google_base_plan }}</dd>

            <dt v-if="hasGoogleData()">Kind (Google):</dt>
            <dd v-if="hasGoogleData()">{{ customer.google_kind }}</dd>

            <dt v-if="hasGoogleData()">Country Code (Google):</dt>
            <dd v-if="hasGoogleData()">{{ customer.google_country_code }}</dd>

            <dt v-if="hasAppleData()">Environment (Apple):</dt>
            <dd v-if="hasAppleData()">{{ customer.apple_environment }}</dd>

            <dt v-if="hasAppleData()">Auto Renewal Status (Apple):</dt>
            <dd v-if="hasAppleData()">{{ customer.apple_auto_renew_status }}</dd>

            <dt v-if="hasAppleData()">Original Transaction ID (Apple):</dt>
            <dd v-if="hasAppleData()">{{ customer.apple_original_transaction_id }}</dd>

            <dt v-if="hasStripeData()">Stripe Customer ID:</dt>
            <dd v-if="hasStripeData()">{{ customer.stripe_customer_id }}</dd>

            <dt v-if="hasStripeData()">Stripe Subscription ID:</dt>
            <dd v-if="hasStripeData()">{{ customer.stripe_subscription }}</dd>

           
          </dl>
        </div>
      </div>
    </div>
    <form v-on:submit.prevent="saveChanges">
      <div class="form-group">
        <label for="fullname">Full Name:</label>
        <input
          type="text"
          v-model="customer.fullname"
          :class="{
            'form-control': true,
            'is-invalid': errors.has('fullname')
          }"
          name="fullname"
          id="fullname"
          placeholder=""
          v-validate="'required'"
        />
        <div class="invalid-feedback">
          Please provide a valid full name.
        </div>
      </div>

      <div class="form-group">
        <label for="email">Email:</label>
        <input
          type="email"
          v-model="customer.email"
          :class="{ 'form-control': true, 'is-invalid': errors.has('email') }"
          name="email"
          id="email"
          placeholder="Email"
          v-validate="'required|email'"
        />
        <div class="invalid-feedback">
          Please provide a valid email.
        </div>
      </div>

      <div class="form-group">
        <label for="country">Country:</label>
        <select class="form-control" name="country" v-model="customer.country">
          <option
            v-for="country in countries"
            v-bind:value="country.value"
            v-bind:key="country.value"
          >
            {{ country.title }}
          </option>
        </select>
      </div>

      <div class="form-group signup-carousel-buttons">
        <button class="btn btn-primary">Save</button>
      </div>
    </form>
    <table class="table" v-if="invoices.length > 0">
      <tr>
        <th>Transaction ID</th>
        <th>Purchase Date</th>
        <th>Original Purchase Date</th>
        <th>Expires Date</th>
        <th>In trial?</th>
        <th>Trial Offer Period</th>
        <th>Product ID</th>
        <th>In App Ownership</th>
      </tr>
      <tr v-for="invoice in invoices" v-bind:key="invoice.transaction_id">
          <td>{{invoice.transaction_id}}</td>
          <td>{{invoice.purchase_date | dateformat }}</td>
          <td>{{invoice.original_purchase_date | dateformat }}</td>
          <td>{{invoice.expires_date | dateformat }}</td>
          <td>{{invoice.is_in_trial}}</td>
          <td>{{invoice.is_in_intro_offer_period}}</td>
          <td>{{invoice.product_id}}</td>
          <td>{{invoice.in_app_ownership_type}}</td>
      </tr>
    </table>
  </div>
</template>

<script>
import countries from "../data/countries.json";

export default {
  methods: {
    hasAppleData: function() {
      if (this.customer.apple_original_transaction_id) {
        return true;
      } else {
        return false;
      }
    },

    hasGoogleData: function() {
      if (this.customer.google_order_id ) {
        return true;
      } else {
        return false;
      }
    },

    hasStripeData: function() {
      if (this.customer.stripe_subscription) {
        return true;
      } else {
        return false;
      }
    },

    saveChanges: function() {
      let token = this.$cookies.get("poolfit_admin_session");

      if (token !== null) {
        let options = {
          headers: { "X-Access-Token": token }
        };
        if (!isNaN(this.$route.params.id)) {
          this.$http
            .patch(
              process.env.VUE_APP_ROOT_API + "/customers/" + this.$route.params.id,
              this.customer,
              options
            )
            .then(response => {
              if (response.status === 200) {
                alert("Record updated successfully");
              }
            });
        }
      }
    }
  },

  data: function() {
    return {
      isLoading: true,
      customer: {},
      countries: countries,
      invoices: []
    };
  },

  created: function() {
    let token = this.$cookies.get("poolfit_admin_session");

    if (token !== null) {
      let options = {
        headers: { "X-Access-Token": token }
      };
      if (!isNaN(this.$route.params.id)) {
        this.$http
          .get(
            process.env.VUE_APP_ROOT_API + "/customers/" + this.$route.params.id,
            options
          )
          .then(response => {
            this.customer = response.data;
            this.isLoading = false;
            this.$http
              .get(
                process.env.VUE_APP_ROOT_API + "/apple/iap/invoices/" + this.$route.params.id,
                options
              )
              .then(invoiceResponse => {
                this.invoices = invoiceResponse.data;
              });
          });
      } else {
        alert("Invalid parameter.");
      }
    }
  }
};
</script>
<style scoped>
dt:before {
  content: "";
  display: block;
}
dt {
  margin-right: 5px;
}
dt, dd {
  display: inline;
  line-height: 1.8em
}
</style>
